import { Component, ElementRef, ViewChild } from '@angular/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'theArchitect.co.in';
  @ViewChild("mySidebar") mySidebar!: ElementRef;

  constructor(library: FaIconLibrary) {

    // Add multiple icons to the library
    library.addIcons(faUserCircle, faBars);
  }

  openNav() {
    var x = this.mySidebar.nativeElement;
    if (x.style.display == "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  closeNav() {
    this.mySidebar.nativeElement.style.display = "none";
  }
}

<meta name="viewport" content="width=device-width, initial-scale=1">
<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.14.0/css/all.css">

<div class="topnav">
   
  
  <span class="active">Welcome to theArchitect.co.in Portal</span>
  <div #mySidebar style="display: none;" id="mySidebar">
    <a href="https://theArchitect.co.in">Home</a>
    <a href="https://worship-songs.theArchitect.co.in">Worship Songs</a>
    <a href="https://my-identity.theArchitect.co.in">My Identity</a>
  </div>

 
  

  <a href="javascript:void(0);" class="icon" (click)="openNav()">
    <i class="fa fa-bars"></i>
  </a>
</div>

<div class="app-background-color">
  <router-outlet></router-outlet>
</div>


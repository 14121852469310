import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";

@Component({
    selector: "Home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.css"]
})

export class HomeComponent implements OnInit {


    constructor() {

    }

    ngOnInit(): void {
    }
}
